import { SimpleContainerConfig } from "./typings";

const buildClassNameToParameterConditionMap = (options: SimpleContainerConfig, regionView: string) => ({
  fullScreenWidth: options.fullWidth,
  whiteBackground: options.backgroundColor === "white",
  "region-bottom-border": options.border === "bottom",
  dxPageContent: regionView === "dx-page-content",
  "region-without-border": options.border && options.border.includes("none"),
  "bex-homepage-module": options.brandModulePadding === "bex_padding",
  "comet-homepage-module": options.brandModulePadding === "comet_padding",
});

export const getExtraClassNameBasedOnParameters = (options: SimpleContainerConfig, regionView: string) => {
  const classMap: any = buildClassNameToParameterConditionMap(options, regionView);

  return Object.keys(classMap)
    .filter((x) => classMap[x])
    .join(" ");
};
